import React from "react"
import { Text } from "theme-ui"
import Layout from "../../components/layout"
import RoleTemplate from "../../components/roles/RoleTemplate"
import Pagination from "../../components/Pagination"

import portraitImage from "../../images/role-portraits/invoker-cutout.png"
import invoker01Image from "../../images/ability-cards/invoker-02.png"
import invoker02Image from "../../images/ability-cards/invoker-01.png"
import invoker03Image from "../../images/ability-cards/invoker-03.png"
import invoker04Image from "../../images/ability-cards/invoker-04.png"

import YAMLData from "../../data/learning-paths/invoker.yaml"

const InvokerPage = () => (
  <Layout>
    <RoleTemplate
      name="invoker"
      src={portraitImage}
      bgGradient="to bottom, #183658, #0A1832"
      introDescription={
        <>
          <Text>The Invoker closes their eyes and utters a mantra, raising their sword in the sky as it ignites in a glorious flame. They peer into the souls of others to divine their intentions and true nature. They ward their friends from harm, and smite those unworthy of their ideals.</Text>
          <Text>The Invoker is a good choice if you want to play a character who channels greater truths to achieve their goals. They can be devout paladins serving a righteous god, oath keeping warriors, quixotic vigilantes, or dark knights.</Text>
        </>
      }
      imgWidth={800}
      imgLeftPos="auto"
      imgRightPos={[2, 4, 6]}
      abilityDescription="The Invoker is a capable battle mage, mixing martial prowess with a touch of wizardry. Their powers are fueled by their bond to their ideals, making the role a great choice for people who want to roleplay a character who serves the gods or a set of beliefs. At the height of their power, The Invoker can transcend reality to seek answers from beyond time and space."
      abilityImg01={invoker01Image}
      abilityImg02={invoker02Image}
      abilityImg03={invoker03Image}
      abilityImg04={invoker04Image}
      yaml={YAMLData}
    />

    <Pagination parent="roles" prev="fighter" next="ranger" />
  </Layout>
)

export default InvokerPage
